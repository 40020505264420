/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import React from 'react';
import { Headline } from '../layout/typography';

const tiers = [
  {
    name: `Founders' Launchpad`,
    href: '/services/launchpad',
    price: 499,
    type: 'Coaching',
    quantity: '3 Sessions',
    description:
      'Noone knows your business quite as you do. But getting the necessary learnings that make or break your startup is hard. I will guide you through this slippery slope to build your startup based on facts, not assumptions.',
    featureIntro: 'Together, we will:',
    features: [
      'Define your actionable Lean Canvas',
      'Identify your riskiest assumptions',
      'Design your 3 experiments to test those assumptions',
      'Execute on those experiments',
    ],
    buttonTitle: `Oh boy, I could sure use some help!`,
  },
  {
    name: `Founders' Shortcut`,
    href: '/services/shortcut',
    price: 2549,
    type: 'Full-Service',
    quantity: '3 Experiments',
    description: `I get it: Time is your most valuable resource. And yet you've understood that cold hard data is necessary to make the right decisions for your startup's success. Then this package is right for you: You provide your idea, I'll do the rest. `,
    featureIntro: 'In the end, you will get:',
    features: [
      'An actionable Lean Canvas',
      '3 experiments covering the riskiest assumptions',
      'Detailed reports containing all decisive metrics',
      'Data-driven proposals for your next steps',
    ],
    buttonTitle: `Whatever, m8, please just take care of this...!`,
  },
];

const Pricing = () => {
  return (
    <div className="bg-white">
      <div className="pt-24 sm:pt-36 lg:pt-36">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <Headline className="text-7xl tracking-tight font-extrabold md:text-9xl">
              Services
            </Headline>
            <p className="max-w-3xl mx-auto text-xl text-gray-900">
              I had to learn this the hard way: Having a startup idea and
              jumping right into the MVP will likely be painful. With my
              experience as an ex-founder and innovation consultant, I will{' '}
              <span className="font-bold">
                maximize your startup&apos;s chances
              </span>{' '}
              of success.{' '}
              <span className="font-bold">Learn fast, cheap and early</span>{' '}
              with experiments that provide{' '}
              <span className="font-bold">the insights you need</span>.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-white sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6 text-center">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-purple-100 text-timothy-purple"
                        id="tier-standard"
                      >
                        {tier.type}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                      {tier.name}
                    </div>
                    <p className="mt-8 text-base font-medium text-gray-500">
                      {tier.quantity} for
                    </p>
                    <p className="text-4xl font-extrabold text-gray-900">
                      {tier.price}€
                    </p>
                    <p className="mt-5 text-lg text-gray-500">
                      {tier.description}
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <p className="text-gray-700">{tier.featureIntro ?? ''}</p>
                    <ul role="list" className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon
                              className="h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="rounded-md shadow">
                      <Link
                        to={tier.href}
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-timothy-purple hover:bg-purple-900"
                        aria-describedby="tier-standard"
                      >
                        {tier.buttonTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-5xl">
            <div className="rounded-lg bg-purple-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
              <div className="flex-1">
                <div>
                  <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                    Self-Paced
                  </h3>
                </div>
                <div className="mt-4 flex items-baseline text-3xl font-extrabold">
                  Founders&apos; Bootcamp
                </div>
                <div className="mt-4 text-lg text-gray-600">
                  In this video course, I will cover all the material conveyed
                  in the Founders&apos; Launchpad. Do it in a day, do it in a
                  month, do it your way&mdash;but do it! Learn how I turn an
                  idea into a successful product for just{' '}
                  <span className="font-semibold text-gray-900">99€</span>.
                </div>
              </div>
              <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                <Link
                  to="/services/bootcamp"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-purple-50"
                >
                  Great, I&apos;m doing this in my own pace!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Pricing };
