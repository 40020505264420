import * as React from 'react';
import Layout from '../../components/layout/layout';
import Blog from '../../components/sections/blog';
import { Pricing } from '../../components/sections/pricing';
import Subscribe from '../../components/sections/subscribe';

const ServicesPage = () => {
  return (
    <Layout title="Services">
      <Pricing />
      <Blog />
      <Subscribe />
    </Layout>
  );
};

export default ServicesPage;
